import React from 'react'
// import Header from '../components/header'
import Footer from '../components/footer'
import AboutCIC from '../templates/Home/aboutCIC'
import TempHome3 from '../templates/Home/tempHome3'

export default function Temp_Home3() {
  return (
    <div>
        {/* <Header /> */}
        <TempHome3 />
        <AboutCIC />
        <Footer />
    </div>
  )
}
