import React from 'react'
import './tempHome3.css'
import glowingman1 from "../../images/flexclipBg2.mp4"

export default function TempHome3() {
  return (
    <div>
       <div style={{ position: "relative" }}>
          {/* <img className="tempHome1-home-image" alt="" src={ContactImage} /> */}
          <video 
            className="tempHome2-home-image" 
            src={glowingman1}
            autoPlay
            loop
            muted 
          />
        </div>
    </div>
  )
}
